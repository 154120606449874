<template>
<v-app id="bg">
  <v-container>
      <v-row align="center" justify="center"> 
      <v-col cols="10">
         <v-img
          max-height="421"
          max-width="421"
          :src="require('../assets/parcelinFull.svg')">
        </v-img>
      </v-col>
      <v-col cols="12">
<v-card
  elevation="2"
  outlined
  shaped
>
<v-card-title> we are opening soon... </v-card-title>
</v-card>
      </v-col>
      </v-row>
  </v-container>
</v-app>
</template>

<script>
export default {

}
</script>

<style scoped>
#bg {
    background-size: cover;
    color: white;
    background-position: center;
    background-attachment: fixed;
    background-image: url("../assets/bg-parcelin.webp");
  }
</style>